import React, { Component } from 'react';

import IconPocketLabs from '../img/brand/PocketLabs-icon.svg';

class WatermarkIcon extends Component {
    render () {
        let iconStyles = {
            opacity: 0.05,
            position: 'absolute',
            top: '5em',
            width: '40%',
            minWidth: '15em',
            left: '58%',
            pointerEvents: 'none'
        }

        return (<img style={iconStyles} src={IconPocketLabs} />);
    }
}

export default WatermarkIcon;
