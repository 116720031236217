import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';

import SocialLinks from '../components/SocialLinks';
import PrimaryCTA from '../components/PrimaryCTA';
import WatermarkIcon from '../components/WatermarkIcon';

import LogoDrizly from '../img/logos/drizly.svg';
import LogoGlance from '../img/logos/glance.png';
import LogoNoyo from '../img/logos/noyo.png';

import LogoTurboTax from '../img/logos/TurboTax.png'
import LogoEbay from '../img/logos/ebay.png'
import LogoFidelity from '../img/logos/Fidelity.png'
import LogoUSBank from '../img/logos/USBank.png'
import LogoToast from '../img/logos/Toast.svg'

import ReactRotatingText from "react-rotating-text"

import Kyle from '../img/bio/kyle.jpg';
import Ankit from '../img/bio/ankit.jpg';

class PageHome extends Component {
    render () {
        let clienLogos = [
            <a className='LogoGrid__logo' href="https://www.glance.net/" target="_blank"><img style={{height:"90%"}} src={LogoGlance} /></a>,
            <a className='LogoGrid__logo' href="https://turbotax.intuit.com/" target="_blank"><img  src={LogoTurboTax} /></a>,
            <a className='LogoGrid__logo' href="https://www.ebay.com/" target="_blank"><img  src={LogoEbay} /></a>,
            <a className='LogoGrid__logo' href="https://www.fidelity.com/" target="_blank"><img  src={LogoFidelity} /></a>,
            <a className='LogoGrid__logo' href="https://www.usbank.com/" target="_blank"><img  src={LogoUSBank} /></a>,
            <a className='LogoGrid__logo' href="https://www.toasttab.com/" target="_blank"><img style={{height:"90%"}}  src={LogoToast} /></a>,
            <a className='LogoGrid__logo' href="https://www.noyo.com/" target="_blank"><img src={LogoNoyo} /></a>,
            <a className='LogoGrid__logo' href="https://www.drizly.com/" target="_blank"><img style={{height:"110%", maxHeight:'110%'}} src={LogoDrizly} /></a>
        ];

        return (
            <div className="l-page-content l-wrap">
                <WatermarkIcon />
                <section name='hero'>
                    <h1>We turn <span className='is-highlighted'>ideas</span> into <ReactRotatingText items={['apps', 'APIs', 'SDKs', 'AI models']} />.</h1>
                    <p className='l-mpw'>Pocket Labs is a team of founding engineers who are passionate about building and launching exceptional products.</p>
                    <PrimaryCTA className='l-mtop-2' />

                </section>

                <section name='clients'>
                    <h3>We’ve worked with</h3>
                    <div className='LogoGrid'>
                        {clienLogos}
                    </div>
                </section>

                <section name='founders'>
                    <h2>The Team</h2>
                        <p>We are multiple time founders with experience at every stage of a startup.   Our team has built apps, APIs and SDKs for clients with hundreds of millions of users. We bring ideas to the next level.</p>
               
                    <div className='TextBlocks--grid'>
                        <div className='TextBlocks__block'>
                            <img src={Kyle} />
                            <h3>Kyle Shank <a href="https://www.linkedin.com/in/kyleshank/" target="_blank"><i className="fab fa-linkedin" aria-hidden="true"></i></a><a href="https://github.com/kyleshank" target="_blank"><i className="fab fa-github" aria-hidden="true"></i></a></h3>
                            <p>Kyle began his career at IBM Rational in Zurich, Switzerland. He co-founded an open source project called <a href="https://en.wikipedia.org/wiki/RadRails" target="_blank">RadRails</a> that was used by over 150,000 developers and later acquired by Aptana. In 2007, Kyle co-founded a machine learning startup called Pressflip that combined support vector machines with persistent search. He started developing iPhone and Android apps in 2009.  Kyle holds two technology patents that were awarded as part of his work for clients.</p>
                        </div>
                        <div className='TextBlocks__block'>
                            <img src={Ankit} />
                            <h3>Ankit Desai <a href="https://www.linkedin.com/in/kitdesai/" target="_blank"><i className="fab fa-linkedin" aria-hidden="true"></i></a><a href="https://github.com/ankitdesai" target="_blank"><i className="fab fa-github" aria-hidden="true"></i></a></h3>
                            <p>Ankit has been building web and mobile apps for almost 15 years. Most recently, he developed a multi-blockchain mobile app that allowed users to view, send, and transact with cryptocurrencies & NFTs. He previously worked for hopOn, as the first engineer, to help develop their technology stack from the ground up. hopOn was acquired by Lola Travel, where Ankit helped scaled the team from 15 to 55 in under a year.</p>
                        </div>
                    </div>
                </section>
                
                <section name='contactus'>
                    <div className='l-two-columns'>
                        <div className='TextBlocks__block'>
                            <h3>Got a project for us?</h3>
                            <p>Let us be your founding engineering team.</p>
                            <br />
                            <PrimaryCTA className='l-mtop-1' />
                        </div>
                        <div className='ContactBlock'>
                            <a href='mailto:hello@pocketlabs.io'>hello@pocketlabs.io</a>
                            <SocialLinks />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
};

export default PageHome;
