import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';
import WatermarkIcon from '../components/WatermarkIcon';
import PrimaryCTA from '../components/PrimaryCTA';
import SocialLinks from '../components/SocialLinks';

class Page404 extends Component {
    render () {
        return (
            <div className="l-page-content l-wrap">
                <WatermarkIcon />
                <h1><span className='is-highlighted'>404</span><br /> Uh Oh! We couldn't find the page with given URL.</h1>
            </div>
        );
    }
};

export default Page404;
