import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';
import WatermarkIcon from '../components/WatermarkIcon';
import PrimaryCTA from '../components/PrimaryCTA';
import SocialLinks from '../components/SocialLinks';
import {SubmitToServer} from '../utils/SubmitToServer';

class LetsWorkTogether extends Component {
    constructor (props) {
        super (props);
        this.state = {
            email: '',
            project: '',
            name: '',
            position: '',
            company: '',
            buttonActive: false,
            successMessage: '',
            errorMessage: '',
            services: [
                {name: 'iOS Development', selected: false, price: 10000},
                {name: 'Android Development', selected: false, price: 10000},
                {name: 'SDK Development', selected: false, price: 10000},
                {name: 'API Development', selected: false, price: 10000},
                {name: 'AI Model Development', selected: false, price: 10000},
                {name: 'Web Development', selected: false, price: 10000},
                {name: 'Blockchain Development', selected: false, price: 10000},
                {name: 'Cloud Deployment', selected: false, price: 10000},
                {name: 'Product Discovery', selected: false, price: 10000},
                {name: 'Product Prototyping', selected: false, price: 10000}
            ],
            selectedServices: [],
            selectedServicesPrice: 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectService = this.handleSelectService.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    validateForm() {
        let flag = (this.state.name != '' && this.state.project != '' && this.validateEmail());

        return flag;
    }

    validateEmail() {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(this.state.email);
    }

    handleSelectService(i, name, price) {
        return (event) => {
            let newState = this.state;

            newState.services[i].selected = (!newState.services[i].selected);

            let temp = this.updateSelectedServices ();

            newState.selectedServices = temp.selectedServices;
            newState.selectedServicesPrice = temp.totalPrice;

            this.setState(newState);
        }
    }

    updateSelectedServices () {
        let selectedServices = [];
        let services = this.state.services;
        let totalPrice = 0;
        console.log(services.length);
        for (let i=0; i<services.length; i++) {
            if (services[i].selected) {
                selectedServices.push(services[i].name);
                totalPrice += services[i].price;
            }
        }

        return {selectedServices, totalPrice};
    }

    handleSubmit(event) {
        // submit stuff to the server
        if (this.validateForm()) {

            SubmitToServer.submit (this.state, (msg) => {
                this.setState({successMessage:'Thank you! We will be in touch soon.'});
            }, (err) => {
                this.setState({errorMessage:'Oops! Something went wrong, please try again.'});
                console.log(err);
            });
        }

        event.preventDefault();
    }

    render () {
        let errorMessage = (this.state.errorMessage ? <div className='ContactForm__error'>{this.state.errorMessage}</div> : '');
        let successMessage = (this.state.successMessage ? <div className='ContactForm__success'>{this.state.successMessage}</div> : '');

        let services = this.state.services.map ((s, i) => {
            let serviceClass = (s.selected ? '--selected' : '');
            return (<div className={'ContactForm__ServiceSelector' + serviceClass} onClick={this.handleSelectService(i, s.name, s.price)}>{s.name}</div>)
        });

        return (
            <div className="l-page-content l-wrap">
                <WatermarkIcon />
                <section name='hero'>
                    <h1>Let’s work together</h1>
                    <p className='l-mpw30'>We look forward to hearing about your project.</p>
                    <p className='l-mpw30'>Send an email to <a href='mailto:hello@pocketlabs.io' className='phone'>hello@pocketlabs.io</a> or fill out the form below.</p>
                </section>
                <section name='contactform'>
                    <form className='ContactForm' onSubmit={this.handleSubmit}>
                        <h3>How can we help?</h3>
                        <div className='ContactForm__Services'>{services}</div>
                        
                        <br /> <br />

                        <h3>Tell us about your project</h3>
                        <textarea type='text' name='project' value={this.state.project} onChange={this.handleChange} placeholder='Enter your project description' />

                        <br /> <br />

                        <h3>About you</h3>
                        <input type='text' name='name' value={this.state.name} onChange={this.handleChange} placeholder='Full name' />
                        <input type='text' name='company' value={this.state.company} onChange={this.handleChange} placeholder='Company (optional)' />
                        <input type='text' name='position' value={this.state.position} onChange={this.handleChange} placeholder='Position (optional)' />
                        <input type='text' name='email' value={this.state.email} onChange={this.handleChange} placeholder='Email' />

                        <br /> <br />

                        <button disabled={!this.validateForm()} className='PrimaryCTA' type="submit" value="SEND REQUEST">SEND REQUEST</button>
                        {errorMessage} {successMessage}
                    </form>
                </section>
            </div>
        );
    }
};

export default LetsWorkTogether;
