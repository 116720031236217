import React, { Component } from 'react';
import { withRouter, BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';

import GlobalNav from './components/GlobalNav';
import GlobalFooter from './components/GlobalFooter';
import ScrollToTop from './components/ScrollToTop';

import PageHome from './pages/PageHome';
import PageLetsWorkTogether from './pages/PageLetsWorkTogether';
import Page404 from './pages/Page404';

class App extends Component {
  render() {
    return (
        <Router><ScrollToTop>
            <div id='page-wrapper'>
                <GlobalNav/>

                <Switch>
                    <Route exact path='/' component={PageHome} />
                    <Route path='/lets-work-together' component={PageLetsWorkTogether} />
                    <Route component={Page404} />
                </Switch>

                <GlobalFooter/>
            </div>
        </ScrollToTop></Router>
    );
  }
}

export default App;
