import React, { Component } from 'react';

class SocialLinks extends Component {
    render() {
        let links = [];

        if (this.props.header) {
            links.push(<a href='mailto:hello@pocketlabs.io' className='phone'>hello@pocketlabs.io</a>)            
        }
        
        links.push(<a href='https://github.com/pocketlabs' target='_blank'><i className="fab fa-github" aria-hidden="true"></i></a>)
        links.push(<a href='https://medium.com/pocket-labs' target='_blank'><i className="fab fa-medium" aria-hidden="true"></i></a>)

        return (
            <div className='SocialLinks'>
                {links}
            </div>
        )
    }
};

export default SocialLinks;
