import React, { Component } from 'react';
import { BrowserHistory, BrowserRouter as Router, Redirect, Link } from 'react-router-dom';

class PrimaryCTA extends Component {
    contextTypes: {
        router: React.PropTypes.object
    }

    render() {
        let classes = (this.props.className || '') + ' PrimaryCTA';
        return (
            <Link to='/lets-work-together'>
                <div className={classes}>
                    LET'S WORK TOGETHER
                </div>
            </Link>
        );
    }
};

export default PrimaryCTA;
