import React, { Component } from 'react';
import BubblesPattern from '../img/brand/BubblesPattern.svg'

class Globalfooter extends Component {
    render() {
        let bubblesStyles = {
            backgroundImage : `url(${BubblesPattern})`,
            backgroundSize: 'auto 100%',
            backgroundRepeatX: 'repeat',
            backgroundRepeatY: 'no-repeat',
            height: '5em'
        };

        console.log('bfefergre', BubblesPattern);

        return (
            <footer>
                <div className='BubblesPattern' style={bubblesStyles}></div>
            </footer>
        )
    }
};

export default Globalfooter;
