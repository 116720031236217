import React, { Component } from 'react';
import LogoPocketLabs from '../img/brand/PocketLabs-full.svg';
import {Link} from 'react-router-dom';

import SocialLinks from './SocialLinks';

class GlobalNav extends Component {
    render() {
        return (
            <nav className='GlobalNav l-wrap'>
                <Link to='/'><img src={LogoPocketLabs} alt='Pocket Labs'/></Link>
                <SocialLinks header={true} />
            </nav>
        )
    }
};

export default GlobalNav;
