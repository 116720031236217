import axios from "axios"

export let SubmitToServer = {
    submit (params, successCallback, errorCallback) {
        // From popup form:
        // params = {
        //    name, email, project
        // }

        axios.post('https://63m3m67wzoydhuaw2vfhqsaini0fkvnp.lambda-url.us-east-1.on.aws', {
            name: params.name,
            email: params.email,
            project: params.project,
            selectedServices: params.selectedServices.reduce((a, s) => {
                return a + ' ' + s + ', ';
            }, ''),
            company: params.company,
            position: params.position
        })
        .then(function (response) {
            successCallback()
        })
        .catch(function (error) {
            errorCallback()
        });
    }
}
